<script>
import Register from "@/views/Registers/Register"
import { createNamespacedHelpers } from 'vuex'
const { mapState: registersState } = createNamespacedHelpers('registers')
export default {
  name: 'IntegrationsRegister',
  extends: Register,
  computed: {
    ...registersState(['registers']),
    computedInputsMaster() {
      return [
        { type: 'switch', label: 'Ativo', value: 'active', sm: 4, md: 2 },
        { type: 'switch', label: 'Requer Admin', value: 'requiresAdmin', sm: 4, md: 2 },
        { type: 'divider', label: 'Permissões' },
        { type: 'switch', label: 'Inclusão', value: 'add', sm: 4, md: 2 },
        { type: 'switch', label: 'Gravar ERP', value: 'sync', sm: 4, md: 2 },
        { type: 'switch', label: 'Esconder Rascunho', value: 'hideSketch', sm: 4, md: 2 },
        { type: 'switch', label: 'Esconder PDF', value: 'hidePdf', sm: 4, md: 2 },
        { type: 'switch', label: 'Visualização', value: 'view', sm: 4, md: 2 },
        { type: 'switch', label: 'Edição', value: 'edit', sm: 4, md: 2 },
        { type: 'switch', label: 'Exclusão', value: 'delete', sm: 4, md: 2 },
        { type: 'switch', label: 'Copiar', value: 'copy', sm: 4, md: 2 },
        { type: 'switch', label: 'Offline', value: 'offline', sm: 4, md: 2 },
        // { type: 'switch', label: 'Algolia', value: 'searchInputAlgolia', default: false, sm: 4, md: 2 },
        { type: 'switch', label: 'Desabilitar Bonificação', value: 'disableBonus', sm: 4, md: 2 },
        { type: 'switch', label: 'Calculo de Preço', value: 'calcPrice', sm: 4, md: 2 },
        { type: 'switch', label: 'Força Calculo de Imposto', value: 'forceCalcImp', sm: 4, md: 2 },
        { type: 'switch', label: 'Força Calculo de Imposto na Impressão', value: 'forceCalcImpPdf', sm: 4, md: 2 },
        { type: 'switch', label: 'Mobile V2', value: 'mobileV2', sm: 4, md: 2 },
        { type: 'switch', label: 'Mobile Salvar no Voltar', value: 'ionBackButtonSave', sm: 4, md: 2 },
        { type: 'switch', label: 'Mobile Form Denso', value: 'formDense', sm: 4, md: 2 },

        { type: 'divider', label: 'Cadastro' },
        { type: 'text-field', label: 'Nome', value: 'name', sm: 4 },
        { type: 'text-field', label: 'Registro', value: 'register', sm: 4 },
        { type: 'text-field', label: 'Coleção', value: 'collection', sm: 4 },
        { type: 'text-field', label: 'Filtro', value: 'filters', sm: 4 },
        { type: 'text-field', label: 'Filtro Vendedor', value: 'filtersSaller', sm: 4 },
        { type: 'text-field', label: 'Filtro Cliente', value: 'filtersClient', sm: 4 },
        { type: 'text-field', label: 'Ordenação', value: 'orderBy', sm: 4 },
        { type: 'text-field', label: 'Coleção de Itens', value: 'collectionDetails', sm: 4 },
        { type: 'text-field', label: 'Campo de bloqueio', value: 'blockedField', sm: 4 },
        { type: 'textarea', label: 'Validação', value: 'validation', sm: 12 },
        // { type: 'text-field', label: 'Vínculo Cód. Vendedor', value: 'sallerCodField', sm: 4 },
        { type: 'text-field', label: 'Mensagem do botão de rascunho', value: 'labelButtonDraft', hint: 'Código JavaScript a ser executado em um eval, deve resultar em uma string.', sm: 4 },
        { type: 'text-field', label: 'Mensagem do botão de envio', value: 'labelButtonSend', hint: 'Código JavaScript a ser executado em um eval, deve resultar em uma string.', sm: 4 },
        { type: 'text-field', label: 'Mensagem de cadastro bem sucedido', value: 'registerSuccessMessage', hint: 'Código JavaScript a ser executado em um eval, deve resultar em uma string.', sm: 4 },
        { type: 'text-field', label: 'Mensagem de edição bem sucedida', value: 'editSuccessMessage', hint: 'Código JavaScript a ser executado em um eval, deve resultar em uma string.', sm: 4 },
        { type: 'text-field', label: 'Mensagem de remoção bem sucedida', value: 'deleteSuccessMessage', hint: 'Código JavaScript a ser executado em um eval, deve resultar em uma string.', sm: 4 },
        { type: 'text-field', label: 'Observação tabela de impostos', value: 'impostosPdfText', hint: 'Visível na exportação do documento PDF abaixo da tabela de impostos', sm: 4 },
        { type: 'text-field', label: 'Observação tabela de totais', value: 'totaisPdfText', hint: 'Visível na exportação do documento PDF abaixo da tabela de totais', sm: 4 },
        { type: 'color-picker', label: 'Cor da tabela no PDF', value: 'colorPdfTable', sm: 4 },
        
        { type: 'divider', label: 'Requisição Externa' },
        { type: 'text-field', label: 'Ordenação', value: 'externalRequest.ordem', sm: 4 },
        { type: 'text-field', label: 'Id de Retorno', value: 'externalRequest.idField', sm: 4 },
        { type: 'textarea', label: 'Query', value: 'externalRequest.query', sm: 12, rows: 5 },

        { type: 'divider', label: 'Cálculo de Impostos' },
        { type: 'switch', label: 'Ativo', value: 'taxCalculation.active', sm: 12 },

        // Resumo Financeiro - Bills
        { type: 'divider', label: 'Resumo Financeiro' },
        { type: 'switch', label: 'Ativo', value: 'bills.active', sm: 12 },
        { type: 'switch', label: 'Card Aberto?', value: 'bills.openCard', sm: 4, hint: 'Define se o card com a listagem de títulos já vem aberto ou ou não' },
        { type: 'text-field', label: 'Campo de gatilho', value: 'bills.fieldGetBills', sm: 4 },
        { type: 'text-field', label: 'Campo data de vencimento', value: 'bills.dueDateField', sm: 4 },
        { type: 'text-field', label: 'Ordenação', value: 'bills.order', sm: 4 },
        { type: 'text-field', label: 'Campo Id', value: 'bills.idField', sm: 4 },
        { type: 'text-field', label: 'Filtro', value: 'bills.filters', sm: 4 },
        { type: 'textarea', label: 'Query', value: 'bills.query', sm: 12, rows: 5 },
        { 
          type: 'jsoneditor', 
          label: 'Dados do cabeçalho', 
          value: 'bills.headers',
          default: [],
          options: {
            mode: 'code',
            templates: [
              {
                label: 'Input Text',
                title: 'Insere um campo de texto',
                className: 'jsoneditor-type-object',
                field: 'campo',
                value: {
                  "type": "string",
                  "order": 1,
                  "title": "título",
                  "x-cols": 12,
                  "x-props": {
                    "outlined": true,
                    "flat": true
                  }
                }
              },
            ]
          },
          //help: 'https://koumoul-dev.github.io/vuetify-jsonschema-form/latest/examples/#basic',
          md: 6
        },
        { 
          type: 'jsoneditor', 
          label: 'Ações Customizadas', 
          value: 'bills.customActions',
          default: [],
          options: {
            mode: 'code',
            templates: [
              {
                label: 'Input Text',
                title: 'Insere um campo de texto',
                className: 'jsoneditor-type-object',
                field: 'campo',
                value: {
                  "type": "string",
                  "order": 1,
                  "title": "título",
                  "x-cols": 12,
                  "x-props": {
                    "outlined": true,
                    "flat": true
                  }
                }
              },
            ]
          },
          //help: 'https://koumoul-dev.github.io/vuetify-jsonschema-form/latest/examples/#basic',
          md: 6
        },

        // Histórico Compras - Purchases
        { type: 'divider', label: 'Histórico Compras' },
        { type: 'switch', label: 'Ativo', value: 'purchases.active', sm: 12 },
        { type: 'switch', label: 'Card Aberto?', value: 'purchases.openCard', sm: 4, hint: 'Define se o card com a listagem de títulos já vem aberto ou ou não' },
        { type: 'text-field', label: 'Campo de gatilho', value: 'purchases.fieldGet', sm: 4 },
        { type: 'text-field', label: 'Ordenação', value: 'purchases.order', sm: 4 },
        { type: 'text-field', label: 'Campo Id', value: 'purchases.idField', sm: 4 },
        { type: 'text-field', label: 'Filtro', value: 'purchases.filters', sm: 4 },
        { type: 'textarea', label: 'Query', value: 'purchases.query', sm: 6, rows: 5 },
        { 
          type: 'jsoneditor', 
          label: 'Dados do cabeçalho', 
          value: 'purchases.headers',
          default: [],
          options: {
            mode: 'code',
            templates: [
              {
                label: 'Input Text',
                title: 'Insere um campo de texto',
                className: 'jsoneditor-type-object',
                field: 'campo',
                value: {
                  "type": "string",
                  "order": 1,
                  "title": "título",
                  "x-cols": 12,
                  "x-props": {
                    "outlined": true,
                    "flat": true
                  }
                }
              },
            ]
          },
          //help: 'https://koumoul-dev.github.io/vuetify-jsonschema-form/latest/examples/#basic',
          md: 6
        },

        // Histórico de Pedidos - OrdersByCustomer
        { type: 'divider', label: 'Histórico de Pedidos' },
        { type: 'switch', label: 'Ativo', value: 'ordersByCustomer.active', sm: 12 },
        { type: 'switch', label: 'Card Aberto?', value: 'ordersByCustomer.openCard', sm: 4, hint: 'Define se o card com a listagem de pedidos já vem aberto ou ou não' },
        { type: 'text-field', label: 'Campo de gatilho', value: 'ordersByCustomer.fieldGet', sm: 4 },
        { type: 'text-field', label: 'Ordenação', value: 'ordersByCustomer.order', sm: 4 },
        { type: 'text-field', label: 'Campo Id', value: 'ordersByCustomer.idField', sm: 4 },
        { type: 'text-field', label: 'Filtro', value: 'ordersByCustomer.filters', sm: 4 },
        { type: 'textarea', label: 'Query', value: 'ordersByCustomer.query', sm: 6, rows: 5 },
        { 
          type: 'jsoneditor', 
          label: 'Dados do cabeçalho', 
          value: 'ordersByCustomer.headers',
          default: [],
          options: {
            mode: 'code',
            templates: [
              {
                label: 'Input Text',
                title: 'Insere um campo de texto',
                className: 'jsoneditor-type-object',
                field: 'campo',
                value: {
                  "type": "string",
                  "order": 1,
                  "title": "título",
                  "x-cols": 12,
                  "x-props": {
                    "outlined": true,
                    "flat": true
                  }
                }
              },
            ]
          },
          //help: 'https://koumoul-dev.github.io/vuetify-jsonschema-form/latest/examples/#basic',
          md: 6
        },

        { type: 'divider', label: 'Menu' },
        { type: 'text-field', label: 'Ícone', value: 'listIcon', sm: 4 },
        { type: 'text-field', label: 'Cor do Ícone', value: 'listIconColor', sm: 4 },
        { type: 'text-field', textType: 'number', label: 'Menu Ordem', value: 'menuOrder', sm: 4 },

        { type: 'divider', label: 'Layout de Impressão' },
        { type: 'switch', label: 'Usa layout de impressão?', value: 'printLayout.active', sm: 2 },
        { type: 'text-field', label: 'Link do layout de impressão', value: 'printLayout.link', sm: 10 },

        { type: 'divider', label: 'Formulário de Impressão' },
        { 
          type: 'jsoneditor', 
          label: 'Dados do cabeçalho impressão', 
          value: 'printCompanyHeaders',
          default: [],
          options: {
            mode: 'code',
            templates: [
              {
                label: 'Input Text',
                title: 'Insere um campo de texto',
                className: 'jsoneditor-type-object',
                field: 'campo',
                value: {
                  "type": "string",
                  "order": 1,
                  "title": "título",
                  "x-cols": 12,
                  "x-props": {
                    "outlined": true,
                    "flat": true
                  }
                }
              },
            ]
          },
          //help: 'https://koumoul-dev.github.io/vuetify-jsonschema-form/latest/examples/#basic',
          md: 5
        },
        { type: 'text-field', label: 'Título', value: 'titleOfPrint', sm: 7 },

        { type: 'divider', label: 'Formulário de E-mail' },
        { type: 'text-field', label: 'E-mail Padrão', value: 'emailConfigs.toDefault', sm: 4 },
        { type: 'text-field', label: 'Assunto', value: 'emailConfigs.subjectDefault', sm: 4 },
        { type: 'textarea',  label: "Corpo do Email", value: 'emailConfigs.textDefault', rows: 4 },
        { type: 'switch', label: 'CC?', value: 'emailConfigs.enableCcDefault', sm: 2, default: true },
        { type: 'text-field', label: 'Cc', value: 'emailConfigs.ccDefault', sm: 4 },
        { type: 'switch', label: 'Bcc?', value: 'emailConfigs.enableBccDefault', sm: 2, default: true },
        { type: 'text-field', label: 'Bcc', value: 'emailConfigs.bccDefault', sm: 4 },

        { type: 'divider', label: 'Formulário', /*help: 'https://koumoul-dev.github.io/vuetify-jsonschema-form/latest/examples/#basic'*/ },
        { 
          type: 'jsoneditor', 
          label: 'Esquema Cabeçalho', 
          value: 'inputsMaster',
          default: [],
          options: {
            mode: 'code',
            templates: [
              {
                label: 'Input Text',
                title: 'Insere um campo de texto',
                className: 'jsoneditor-type-object',
                field: 'campo',
                value: {
                  "type": "string",
                  "order": 1,
                  "title": "título",
                  "x-cols": 12,
                  "x-props": {
                    "outlined": true,
                    "flat": true
                  }
                }
              },
            ]
          },
          //help: 'https://koumoul-dev.github.io/vuetify-jsonschema-form/latest/examples/#basic',
          md: 6
        },
        {
          type: 'jsfChild',
          label: 'Ações',
          value: 'customActions',
          schema: {
            type: "object",
            allOf: [
              {
                properties: {
                  name: {
                    type: "string",
                    title: "Nome",
                  },
                  icon: {
                    type: "string",
                    title: "Ícone",
                  },
                  color: {
                    type: "string",
                    title: "Cor",
                  },
                  actionRunner: {
                    type: "string",
                    title: "Ação",
                  },
                  hide: {
                    type: "string",
                    title: "Ocultar",
                  },
                  disabled: {
                    type: "string",
                    title: "Desabilitar"
                  },
                  listRegister: {
                    type: "boolean",
                    title: "Listagem"
                  }
                }
              }
            ]
          }
        },
        {
          type: 'jsfChild', 
          label: 'Filtros',
          value: 'dataFilters', 
          schema: {
            type: "object",
            allOf: [
              {
                properties: {
                  label: {
                    type: "string",
                    title: "Título",
                  },
                  filterKey: {
                    type: "string",
                    title: "Coluna",
                  },
                  userRules: {
                    type: "array",
                    title: "Papéis com acesso ao filtro",
                    items: {
                      type: "string",
                      oneOf: this.computedUserRules.map(ur => ({ const: ur.id, title: ur.name }))
                    }
                  }
                }
              },
              {
                type: "object",
                title: "Método",
                oneOf: [
                  {
                    title: "Digitar valores",
                    properties: {
                      source: {
                        type: "string",
                        const: "input",
                      },
                      values: {
                        type: 'array',
                        title: 'Valores',
                        items: {
                          type: 'string',
                        },
                        "x-props": {
                          delimiters: [','],
                          smallChips: true,
                          height: 32,
                        },
                      }
                    }
                  },
                  {
                    title: "Algolia",
                    properties: {
                      source: {
                        type: "string",
                        const: "algolia"
                      },
                      algoliaIndex: {
                        type: "string",
                        title: "Índice",
                      },
                      itemKey: {
                        type: "string",
                        title: "Atributo do item",
                      },
                      previewFilter: {
                        type: "string",
                        title: "Filtro Preview (opcional)",
                      },
                      fieldsToSearch: {
                        type: 'array',
                        title: 'Atributos a serem buscados',
                        items: {
                          type: 'string',
                        },
                        "x-props": {
                          delimiters: [','],
                          smallChips: true,
                          height: 32,
                        },
                      },
                      fieldsToShow: {
                        type: 'array',
                        title: 'Atributos a serem exibidos na busca',
                        items: {
                          type: 'string',
                        },
                        "x-props": {
                          delimiters: [','],
                          smallChips: true,
                          height: 32,
                        },
                      },
                      multiple: {
                        type: 'boolean',
                        title: 'Seleção múltipla',
                        "x-display": 'switch',
                      },
                      noEmpty: {
                        type: 'boolean',
                        title: 'Exigir texto para realizar a busca',
                        "x-display": 'switch',
                        description: "Essa opção define se os itens referentes ao campo não serão exibidos quando a busca estiver vazia, em caso positivo, será solicitado que o usuário digite para buscar."
                      }
                    }
                  }
                ]
              }
            ]
          }
        },

        { type: 'divider', label: 'Formulário de itens' },
        /**
         * Select que escolhe o tipo do form de details, sua escolha altera os campos logo abaixo
         */
        {
          type: 'select',
          label: 'Tipo do formulário de itens',
          value: 'typeDetails',
          items: [{value: 'none', text: 'Nenhum'}, {value:'data-table', text: 'Tabela'}, {value:'form', text:'Formulário'}],
          default: 'none',
          cols: 12,
        },
        { 
          hide: ({item}) => item && item.typeDetails != 'form',
          send: item => item.typeDetails == 'form',
          type: 'jsoneditor', 
          label: 'Esquema Itens', 
          value: 'schemaDetails',
          options: {
            mode: 'code',
            templates: [
              {
                label: 'Input Text',
                title: 'Insere um campo de texto',
                className: 'jsoneditor-type-object',
                field: 'campo',
                value: {
                  "type": "string",
                  "order": 1,
                  "title": "título",
                  "x-cols": 12,
                  "x-props": {
                    "outlined": true,
                    "flat": true
                  }
                }
              },
            ]
          },
          help: 'https://koumoul-dev.github.io/vuetify-jsonschema-form/latest/examples/#basic',
          md: 6
        },
        {
          hide: ({item}) => item && item.typeDetails != 'data-table',
          send: item => item.typeDetails == 'data-table',
          type: 'text-field',
          label: 'tableCollection',
          value: 'tableCollection',
          md: 3,
          sm: 6,
          cols: 12,
        },
        {
          hide: ({item}) => item && item.typeDetails != 'data-table',
          send: item => item.typeDetails == 'data-table',
          type: 'text-field',
          label: 'tableIdFrom',
          value: 'tableIdFrom',
          md: 3,
          sm: 6,
          cols: 12,
        },
        {
          // hide: ({item}) => item && item.typeDetails != 'data-table',
          // send: item => item.typeDetails == 'data-table',
          type: 'switch',
          label: 'Amarração Cliente?',
          value: 'tableSA7',
          default: false,
          md: 3,
          sm: 6,
          cols: 12,
        },
        {
          // hide: ({item}) => item && item.typeDetails != 'data-table',
          // send: item => item.typeDetails == 'data-table',
          type: 'switch',
          label: 'Amarração Cliente - Mostrar todos?',
          value: 'tableSA7ShowAll',
          default: false,
          md: 3,
          sm: 6,
          cols: 12,
        },
        {
          // hide: ({item}) => item && item.typeDetails != 'data-table',
          // send: item => item.typeDetails == 'data-table',
          type: 'switch',
          label: 'Itens sob Demanda?',
          value: 'onDemand',
          default: false,
          md: 3,
          sm: 6,
          cols: 12,
        },
        {
          type: 'switch',
          label: 'Não usar tabela de preço',
          value: 'useDA0',
          default: false,
          md: 3,
          sm: 6,
          cols: 12,
        },
        {
          // hide: ({item}) => item && item.typeDetails != 'data-table',
          // send: item => item.typeDetails == 'data-table',
          type: 'switch',
          label: 'Não Limpar Tabela?',
          value: 'notClearItemsDetailsEditable',
          default: false,
          md: 3,
          sm: 6,
          cols: 12,
        },
        {
          // hide: ({item}) => item && item.typeDetails != 'data-table',
          // send: item => item.typeDetails == 'data-table',
          type: 'switch',
          label: 'Esconder Filtros',
          value: 'hideFilters',
          default: false,
          md: 3,
          sm: 6,
          cols: 12,
        },
        {
          hide: ({item}) => item && item.typeDetails != 'data-table',
          send: item => item.typeDetails == 'data-table',
          type: 'text-field',
          label: 'tableIdForeign',
          value: 'tableIdForeign',
          md: 3,
          sm: 6,
          cols: 12,
        },
        {
          hide: ({item}) => item && item.typeDetails != 'data-table',
          send: item => item.typeDetails == 'data-table',
          type: 'text-field',
          label: 'fieldsReturn',
          value: 'fieldsReturn',
          md: 9,
          sm: 6,
          cols: 12,
        },
        {
          hide: ({item}) => item && item.typeDetails != 'data-table',
          send: item => item.typeDetails == 'data-table',
          type: 'text-field',
          label: 'querySubcollection',
          value: 'querySubcollection',
          md: 12,
          sm: 12,
          cols: 12,
        },
        // {
        //   hide: item => item.typeDetails != 'data-table',
        //   send: item => item.typeDetails == 'data-table',
        //   type: 'text-field',
        //   label: 'tableItem',
        //   value: 'tableItem',
        //   md: 3,
        //   sm: 6,
        //   cols: 12,
        // },
        {
          hide: ({item}) => item && item.typeDetails != 'data-table',
          send: item => item.typeDetails == 'data-table',
          type: 'text-field',
          label: 'tableSubcollection',
          value: 'tableSubcollection',
          md: 3,
          sm: 6,
          cols: 12,
        },
        {
          hide: ({item}) => item && item.typeDetails != 'data-table',
          send: item => item.typeDetails == 'data-table',
          type: 'text-field',
          label: 'Filtros',
          value: 'filtersSubcollection',
          md: 3,
          sm: 6,
          cols: 12,
        },
        {
          hide: ({item}) => item && item.typeDetails != 'data-table',
          send: item => item.typeDetails == 'data-table',
          type: 'text-field',
          label: 'Ordenação',
          value: 'orderSubcollection',
          md: 3,
          sm: 6,
          cols: 12,
        },
        {
          hide: ({item}) => item && item.typeDetails != 'data-table',
          send: item => item.typeDetails == 'data-table',
          type: 'text-field',
          label: 'Ordenação Offline',
          value: 'orderSubcollectionOffline',
          md: 3,
          sm: 6,
          cols: 12,
        },
        {
          hide: ({item}) => item && item.typeDetails != 'data-table',
          send: item => item.typeDetails == 'data-table',
          type: 'text-field',
          label: 'Filtro de Itens',
          value: 'filterItems',
          hint: 'Código em JavaScript de uma função para filtrar os itens da tabela logo antes de enviar os dados do formulário.',
          md: 12,
          sm: 12,
          cols: 12,
        },
        { 
          type: 'switch', label: 'Importar XLS', value: 'exportXls', sm: 6, md: 3, default: true
        },
        {
          hide: ({item}) => item && item.typeDetails != 'data-table',
          send: item => item.typeDetails == 'data-table',
          type: 'text-field',
          label: 'Nome do xlsx',
          value: 'xlsxName',
          md: 9,
          sm: 12,
          cols: 12,
        },
        { 
          hide: ({item}) => item && item.typeDetails != 'data-table',
          send: item => item.typeDetails == 'data-table',
          type: 'jsoneditor', 
          label: 'Headers da Tabela', 
          value: 'headersDetails',
          default: [],
          options: {
            mode: 'code',
            templates: [
              {
                label: 'Input Text',
                title: 'Insere um campo de texto',
                className: 'jsoneditor-type-object',
                field: 'campo',
                value: {
                  "type": "string",
                  "order": 1,
                  "title": "título",
                  "x-cols": 12,
                  "x-props": {
                    "outlined": true,
                    "flat": true
                  }
                }
              },
            ]
          },
          help: 'https://koumoul-dev.github.io/vuetify-jsonschema-form/latest/examples/#basic',
          md: 6
        },
        { 
          type: 'jsoneditor', 
          label: 'Totalizadores do Cabeçalho', 
          value: 'totalizeMaster',
          default: [],
          options: { mode: 'code'},
          help: 'https://koumoul-dev.github.io/vuetify-jsonschema-form/latest/examples/#basic',
          md: 6
        }
      ]
    },
    computedExportPdf() { return false },
    computedUserRules() {
      return this.registers['userRules']
    }
  },
  methods: {
    handleData(item) {
      item.dataFilters = item.dataFilters.filter(filter => !filter.delete).map(filter => filter.data)
      return item
    },
  }
}
</script>

<style>
</style>